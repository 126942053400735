import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './News/Content'
import { listNewsEventData } from '../service/apiService';
import { useParams,useLocation } from "react-router-dom";
import { NewsData } from './News/NewsData';
import { config } from '../config/rest';

export const TenderDetails = (props) => {
    let { newsData } = useParams(); 
    const location = useLocation()
  
    const tenDerData=location.state.newsDetails.attributes.tenderFile.data!==null?config.IMG_URL+
    location.state.newsDetails.attributes.tenderFile.data.attributes.url:'';

  useEffect(() => { 
    window.scrollTo(0, 0);
  },[]);

 
  return (
    
   <>
     <BreadCumb bgImage={"url('/assets/img/banner/aboutupdated.jpg"} 
     
     routeLink={"aboutUs/about"} mainRoute={"Home"} 
    route={"About"} title={"Tender Details"}/>
     <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
                <div className="ps-xl-2-3">
                    <h2 className="h2 mb-1-6 oragneTxt">{location.state.newsDetails.attributes.title}</h2> 
                  
                    <div
                    dangerouslySetInnerHTML={{ __html: location.state.newsDetails.attributes.description }}/>
                    <a href={tenDerData}  target="_blank" className="butn  sm"><span>Download Tender</span></a>
                </div> 
            </div>

    
   </>
  )
}
