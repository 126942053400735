import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../../component/Language/Lang';

export const ProductThumb = (props) => {
    const langData=useSelector(state=>state.cart);
  const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }


   useEffect(() => {
    window.scrollTo(0, 0);
  },[langData]);
 

  return (
   <div className="card-style1">
                            <div className="card-image position-relative">
                                <img src={""+props.thumb+""} className="card-img-top" alt="..."/>
                            </div>
                            <div className="card-body pt-2-3 position-relative">
                                <div className="icon-box position-absolute right-10 top-n20">
                                    <img src={""+props.icon+""}  className="z-index-9 position-relative" alt="..."/>
                                    <div className="box-circle primary"></div>
                                </div>
                                <h3 className="h4 mb-3"><Link to="/">{props.title}</Link></h3>
                                <p className="mb-4">{props.shortDesc}</p>
                                
                                <div className="bd-example">
                                {props.isAbt==true?
         <a href={props.isAbtDownloadUrl}  target="_blank" className="butn  sm"><span>
            { Lang[langData.lang].prdAbtBtn} </span></a>
         :''}
        {props.isProps && <a href={props.proposalDownloadlink}  target="_blank" className="butn  sm"><span>
        { Lang[langData.lang].prdProposalBtn} </span></a>}
         {props.isClaim && <a href={props.claimDownloadlink}  target="_blank" className="butn  sm"><span>
         { Lang[langData.lang].prdClaimBtn}</span></a>}
         
      </div>
                                {/*<Link to={"/"+props.routeName+""} className="text-secondary text-primary-hover font-weight-600">Read more <i className="ti-arrow-right ms-2 align-middle display-30"></i></Link>*/}
                            </div>
                        </div>
                
  )
}
