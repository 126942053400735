import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'

import { listNewsEventData } from '../service/apiService';
import { Content } from './Gallery/Content';

export const Gallery = () => {
  const [newsContact,setnewsContact]=useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsProduct(); 
  },[]);

  const getNewsProduct=async()=>{
    const newsData=await listNewsEventData();
    console.log(newsData);
    setnewsContact(newsData.data.data);
  }

  return (
   <>
     <BreadCumb bgImage={"url('assets/img/banner/newsevents.jpg')"} 
     routeLink={"Gallery"} mainRoute={"Home"} 
    route={"Gallery"} title={"Gallery"}/>
    <Content newsData={newsContact}/>
   </>
  )
}
