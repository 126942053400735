import React ,{useState,useEffect} from 'react'
import { ProductThumb } from './ProductThumb'
import { config } from '../../config/rest';
import { produothersPage, produothersPagear } from '../../service/apiService'
import { useSelector,useDispatch } from "react-redux";

export const Others = () => {
  const [comProduct,setcomroduct]=useState([]);
  const langData=useSelector(state=>state.cart);
  const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }

  useEffect(() => {
    window.scrollTo(0, 0);
    getcomProduct();
  });
  const getcomProduct=async()=>{
    const comProduct=langData.lang==='ar'?await produothersPagear() :await produothersPage();
    setcomroduct(comProduct.data.data);
  }

  return (
    <section>
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>Our Products</span>
            <h2 className="display-22 display-sm-18 display-md-16 display-lg-11 mb-0">Others</h2>
        </div>
        <div className="row g-lg-5 mt-n2-9">
        {comProduct.map(function(data,index) {
                  const apro=data.attributes.proposalform.data!==null?config.IMG_URL+data.attributes.proposalform.data.attributes.url:'';
                  const aclaim=data.attributes.claimform.data!==null?config.IMG_URL+data.attributes.claimform.data.attributes.url:'';
                  const aabt='';
        return (
                <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms" key={index}>
                    <ProductThumb
                     thumb={data.attributes.prdimage.data ? config.IMG_URL+data.attributes.prdimage.data.attributes.url:''}
                     icon={"/assets/img/icons/icon-03.png"}
                     title={data.attributes.prdTitle}
                     shortDesc={""}
                     routeName={"product"}
                     proposalDownloadlink={apro}
                     claimDownloadlink={aclaim}
                     isClaim={aclaim==''?false:true}
                     isProps={apro==''?false:true}
                     isAbt={aabt==''?false:true}
                     isAbtDownloadUrl={aabt}
                     />
                     </div>
        )})}
                    

                    
                </div>
            </div>
   {/*<img src="/assets/img/content/shape2.png" className="position-absolute top-0 end-0 d-none d-md-block" alt="..."/>
    <img src="/assets/img/content/shape6.png" className="position-absolute bottom-20 left-5 ani-top-bottom d-none d-sm-block" alt="..."/>*/}
</section>
  )
}
