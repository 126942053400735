import React, { useState } from 'react';
import { config } from '../../config/rest';




export const Content = (props) => {
console.log('dfkjdsfkjdfkd',props.newsData);
  return (
    <section>
      <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
          <span>Gallery</span>
          <h2 className="h1 mb-0">Gallery</h2>
        </div>
        <div className="row about-style">
          {props.newsData && props.newsData.sort((a, b) => b.attributes.date > a.attributes.date ? 1 : -1)
          .map(function (data, index) {
            return (
              <div classNameName="col-lg-12 wow fadeIn" data-wow-delay="400ms" key={data.id}>
              <div classNameName="ps-xl-2-3">
                <div className="inner-title whyChoose">
                  <h2 className="h4 mb-0">{data.attributes.title}</h2>
                  <h3 className="h5 mb-3">Date : {data.attributes.date}</h3>
                </div>
              </div>
              <div className="row justify-content-center lightbox-gallery">
                <div className="row photos">
                  {data.attributes.image.data  && data.attributes.image.data.map(function (item, index) {
                    return (
                      <div className="col-sm-6 col-md-4 col-lg-3 item" key={item.id}>
                        <a href={config.IMG_URL+item.attributes.url} data-lightbox="photos">
                          <img className="img-fluid" src={config.IMG_URL+item.attributes.url} /></a></div>
                    )
                  })}
                </div>
  
              </div>
            </div>
                    )

          })}

         


        </div>
      </div>
    </section>
  )
}
