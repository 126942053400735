import React,{useEffect, useState} from 'react'
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './Abouts/Content'
import { useParams } from "react-router-dom";
import { CeoMessage } from './Abouts/CeoMessage';
import { TeamStructure } from './Abouts/TeamStructure';
import { Financial } from './Abouts/Financial';
import { FinancialRating } from './Abouts/FinancialRating';
import { useSelector,useDispatch } from "react-redux";
import { Performance } from './Abouts/Performance';
import { abtPage, abtPagear, ceomessagePage, ceomessagePagear, tenderPage, tenderPagear } from '../service/apiService';
import { Tender } from './Abouts/Tender';
import loadjs from 'loadjs';


export const About = () => {
  const langData=useSelector(state=>state.cart);
  //loadjs('assets/js/main.js', () => {});
    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }

  
  let { titleAbout } = useParams(); 
  const[titlename,setName]=useState(titleAbout);
  const[abtContent,setabtContent]=useState([]);
  const[ceoMess,setceomessContent]=useState([]);
  const[tenderData,settenderData]=useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAbtContent();
    getCeomessContent();
    console.log(`/something/${titleAbout}`);
    setName(titleAbout);
    getTender();
},[titleAbout,langData]);

const getAbtContent=async()=>{
  const aboutUs=langData.lang==='ar'?await abtPagear() :await abtPage();
  
  setabtContent(aboutUs.data.data);
}

const getCeomessContent=async()=>{
  const ceoMessContent=langData.lang==='ar'? await ceomessagePagear():await ceomessagePage();
  console.log(ceoMessContent.data.data);
  setceomessContent(ceoMessContent.data.data);
}
const getTender=async()=>{
  const tenderData=langData.lang==='ar'? await tenderPagear() :await tenderPage();
  settenderData(tenderData.data.data);
}

  return (
    <>
    <BreadCumb bgImage={"url('/assets/img/banner/aboutupdated.jpg')"} routeLink={"about"} mainRoute={"Home"} 
    route={"About"} title={"About Us"}/>
    {titleAbout==='about'?
    <Content abtData={abtContent}/>:
    titleAbout==='CEOmessage'?
    <CeoMessage ceoMessData={ceoMess}/>:
    titleAbout==='team'?
    <TeamStructure/>:
    titleAbout==='financial'?
    <Financial/>:
    titleAbout==='financialRating'?
    <FinancialRating/>:
    titleAbout==='tender'?
    <Tender tenderData={tenderData}/>:
    titleAbout==='performance'?
    <Performance/>:
    <></>}
  
    </>
  )
}
