import React ,{useState,useEffect}from 'react'
import {Link} from 'react-router-dom'
import { financialAnnualReport } from '../../service/apiService';
import { config } from '../../config/rest';

export const Financial = () => {
    const [annualReport,setAnnualReport]=useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        getAnnualReport();
       
      },[]);

      const getAnnualReport=async()=>{
        const getAnnualReport=await financialAnnualReport();
        setAnnualReport(getAnnualReport.data.data[0].attributes && getAnnualReport.data.data[0].attributes.AnnualReport);
        

      } 

  return (
    <section>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="row mb-6 mb-lg-8">
                    <div className="col-md-6 position-relative elements-block mb-6 mb-md-0">

                       <ul className="list-style1 mb-0">
                            <li><Link to='/aboutUs/financialRating'>Financial Rating</Link></li>
                            <li><Link to='/aboutUs/performance'>Performance</Link></li>
                           
                        </ul>
                        </div>
                        
                      </div>

                      <div className="row mb-6 mb-lg-8">
                    <div className="col-md-4 position-relative elements-block mb-6 mb-md-0">

                        <div className="inner-title">
                            <h2 className="h4 mb-0">Annual Reports</h2>
                        </div>

                        <ul className="list-style1 mb-0">
                        
                        {annualReport.map(function(data,index) {
                            return(
                                <li key={index}>
                                    <a href={config.IMG_URL+data.file.data.attributes.url} target='_blank'>
                                        {data.Title}
                                    </a>
                                </li>
                           
                            )})}
                            
                        </ul>
                        </div>

                       
              
                      </div>

                      
                      </div>
                      </div>
                      </div>
                      </section>
  )
}
